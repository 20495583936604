<template>
  <DxDataGrid
    :data-source="historyStore"
    :show-borders="true"
    :show-column-lines="true"
    :columns="columns"
    :columnAutoWidth="true"
    @cellDblClick="onDblClick">
    <DxSorting mode="none"/>
  
    <template #colorsCellTemplate="{ data }">
      <div v-if="data.data.color != null">
        <img class="status-icon-cell middle" :src="require('@/assets/colors/' + data.data.color + '.png')">
        <span class="middle">{{ data.data.color }}</span>
      </div>           
    </template>
    
    <template #fileCellTemplate="{ data }">
      <span v-if="data.column.dataField.includes('file') && data.data[data.column.dataField]" class="dx-icon-download"/>
    </template>

    <template #fileIdCellTemplate="{ data }">
      {{getFileName(data)}}
    </template>

    <template #imageCellTemplate="{ data }">
       <img v-if="data.value"
            class='imageCell' 
            :src="getImageURL(data.value)"/>
     </template>

  </DxDataGrid>
</template>

<script>
  import { DxDataGrid,
           DxSorting } from "devextreme-vue/data-grid";
  import { createStore } from "devextreme-aspnet-data-nojquery";
  import store from "../../store";
  import fn from "../../utils/function";

  export default {
    components: {
      DxDataGrid,
      DxSorting
    },
    props: {
      masterDetailData: {
          type: Object,
          default: () => ({})
      },
      url: String,
      keyName: String,
      columns: Object,
      key1: String,
      key2: String 
    },
    computed: {
      historyStore() {
        return this.masterDetailData.key === null ? null : {
          store: createStore({
            key: this.keyName,
            loadUrl: this.url,
            loadParams: {key: this.masterDetailData.key, key1: this.key1, key2: this.key2},
            onBeforeSend: (method, ajaxOptions) => {
               ajaxOptions.headers = { token: store.state.token, userId: store.state.userId  };  
            }
          })
        };
      }
    },
    methods:{
      onDblClick(e) {
        if(e.column.dataField.toUpperCase().includes('FILEID')) {
          fn.downLoadFileFromId(e);
        } else {
          fn.downLoadFile(e);  
        }
      },
      getImageURL(fileId) {
        return encodeURI('/api/displayImage?fileId=' + fileId);
      },
      getFileName(data) {
        var columnName = data.column.dataField;
        var targetColumn = columnName.replace('FileId', 'File') + 'Name';
        if (!data.data[targetColumn]) {
          targetColumn = columnName.replace('fileId', 'file') + 'Name';
        }
        return data.data[targetColumn];
      }
    }
  };
</script>

<style scoped>
  .status-icon-cell {
    height: 25px;
    display: inline-block;
    margin-right: 8px;
  }

  .middle {
    vertical-align: middle;
  }

  .imageCell {
    max-width: 350px;
  }
</style>