import store from './store';

export default {

  getMenuItems() {
    let menuItem;
    let auth = store.getters.getAuth;

    let menuItemsSystem = {
      text: "바로가기",
      icon: "home",
      items: [{
        text: "HOME",
        path: "/mes/home"
      },
      {
        text: "Manual",
        path: "/manual"
      }
      ]
    }

    let menuItemAdmin = {
      text: "관리자",
      icon: "preferences",
      items: [
        {
          text: "사원 정보",
          path: "/erp/eusr"
        },
        {
          text: "로그인 이력",
          path: "/mes/mvul"
        },
        {
          text: "Check Item 관리",
          path: "/erp/erci"
        },
        {
          text: "Check List 관리",
          path: "/erp/ercl"
        },
        {
          text: "제품 Option 관리",
          path: "/erp/erpo"
        },
        {
          text: "제품 Option Group 관리",
          path: "/erp/erog"
        }
      ]
    }

    menuItem = [
      {
        text: "기준정보",
        icon: "comment",
        items: [
          {
            text: "공정 정보",
            path: "/erp/emog"
          },
          {
            text: "공급업체 정보",
            path: "/erp/emsi"
          },
          {
            text: "품목 정보",
            path: "/erp/emmc"
          },
          {
            text: "자재 정보",
            path: "/erp/emmg"
          },
          {
            text: "자재 가공 정보(품목)",
            path: "/erp/emmf"
          },
          {
            text: "자재 가공 정보(자재)",
            path: "/erp/emmm"
          },
          {
            text: "자재 검사 기준(품목)",
            path: "/erp/eisp"
          },
          {
            text: "자재 검사 기준(자재)",
            path: "/erp/eism"
          },
          {
            text: "제품 정보",
            path: "/erp/epmg"
          },
          {
            text: "제품 검사 기준",
            path: "/erp/ecsi"
          },
          {
            text: "장비 정보",
            path: "/erp/emeq"
          },
          {
            text: "장비 PM 기준",
            path: "/erp/eeps"
          },
          {
            text: "메일 수신자 정보",
            path: "/erp/eemi"
          },
          {
            text: "자재 단가 정보(년)",
            path: "/erp/eruy"
          },
          {
            text: "자재 단가 정보(월)",
            path: "/erp/erup"
          }
        ]
      },
      {
        text: "공사 Project 관리",
        icon: "globe",
        items: [
          {
            text: "Project 등록",
            path: "/erp/preg"
          },
          {
            text: "영업 담당자 지정",
            path: "/erp/pasm"
          },
          {
            text: "고객 Contact",
            path: "/erp/pcct"
          },
          {
            text: "제품 등록",
            path: "/erp/prep"
          },
          {
            text: "설계 요청",
            path: "/erp/pdrq"
          },
          {
            text: "설계",
            path: "/erp/pdds"
          },
          {
            text: "설계 납품",
            path: "/erp/pdcf"
          },
          {
            text: "설계 납품 확인",
            path: "/erp/prjc"
          }
        ]
      },
      {
        text: "영업",
        icon: "runner",
        items: [
          {
            text: "건설 현장 정보",
            path: "/erp/emhj"
          },
          {
            text: "강판 시공 정보",
            path: "/erp/epsi"
          }
        ]
      },
      {
        text: "현장 관리",
        icon: "box",
        items: [
          {
            text: "자재 청구",
            path: "/erp/eehm"
          },
          {
            text: "자재 사용 정보(현장)",
            path: "/erp/eumc"
          },
          {
            text: "제품 검사",
            path: "/mes/misc"
          },
          {
            text: "제품 검사 성적서",
            path: "/mes/qcoc"
          },
          {
            text: "공급업체 품목 성적서",
            path: "/erp/esci"
          }
        ]
      },
      {
        text: "자재",
        icon: "car",
        items: [
          {
            text: "자재 입고",
            path: "/mes/mmis"
          },
          {
            text: "자재 이동",
            path: "/mes/mpou"
          },
          {
            text: "자재 수량 보정",
            path: "/mes/mcqt"
          },
          {
            text: "자재 분할",
            path: "/mes/mspl"
          },
          {
            text: "자재 병합",
            path: "/mes/mmrg"
          },
          {
            text: "자재 가공",
            path: "/mes/mcmt"
          },
          {
            text: "자재 Batch 구성",
            path: "/mes/mcbt"
          },
          {
            text: "자재 출고",
            path: "/mes/mmos"
          },
          {
            text: "라벨 발행",
            path: "/mes/mplb"
          },
          {
            text: "재물 조사",
            path: "/mes/miip"
          },
          {
            text: "Lot 속성 변경",
            path: "/mes/mcls"
          },
          {
            text: "라벨 재 발행(날짜 별)",
            path: "/mes/mpls"
          },
          {
            text: "태양광 모듈 발주 정보",
            path: "/mes/mrsp"
          }
        ]
      },
      {
        text: "장비",
        icon: "toolbox",
        items: [
          {
            text: "장비 이동",
            path: "/mes/epou"
          },
          {
            text: "BM 등록",
            path: "/mes/esbm"
          },
          {
            text: "BM 완료",
            path: "/mes/eebm"
          },
          {
            text: "PM 결과 입력",
            path: "/mes/eepm"
          },
          {
            text: "시험 점검",
            path: "/mes/etcs"
          }
        ]
      },
      {
        text: "품질",
        icon: "warning",
        items: [
          {
            text: "자재 검사",
            path: "/mes/misp"
          },
          {
            text: "자재 검사 성적서(구)",
            path: "/mes/qcoa"
          },
          {
            text: "자재 검사 성적서",
            path: "/mes/qcon"
          },
          {
            text: "부적합 자재 등록",

            path: "/mes/mphd"
          },
          {
            text: "부적합 자재 해제",
            path: "/mes/mrhd"
          }
        ]
      },
      {
        text: "조회",
        icon: "rowfield",
        items: [
          {
            text: "자재 재고 현황",
            path: "/mes/mvlm"
          },
          {
            text: "자재 이력",
            path: "/mes/mvlh"
          },
          {
            text: "건설 현장 자재 사용 이력",
            path: "/mes/mvch"
          },
          {
            text: "자재 입고/출고 이력",
            path: "/mes/mvio"
          },
          {
            text: "재물 조사 이력",
            path: "/mes/miir"
          },
          {
            text: "자재 청구서",
            path: "/mes/mvrm"
          },
          {
            text: "장비 현황",
            path: "/mes/mvem"
          },
          {
            text: "장비 이력",
            path: "/mes/mveh"
          },
          {
            text: "자재 검사 결과",
            path: "/mes/mvir"
          },
          {
            text: "자재 단가 정보",
            path: "/mes/mvup"
          },
          {
            text: "Project 현황",
            path: "/mes/mvps"
          }
        ]
      }
    ]

    menuItem.unshift(menuItemsSystem);

    if (auth == 'ADMIN') {
      menuItem.push(menuItemAdmin);
    }

    return menuItem;
  }
}