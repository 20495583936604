<template>
  <div id="Container">
    <DxLoadPanel
      :position="position"
      :visible="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :hide-on-outside-click="false"
      message="성적서 생성중...."
      shading-color="rgba(0,0,0,0.4)"
    />
    <DxScrollView direction="vertical">
      <Transaction :ref="transactionRefName" 
                   :hideCode="true"
                   :hideQuantity="true"
                   :hideOperation="true"
                   :hideExecute="true"
                   :hideTimeEdit="true"
                   :withTerminateLot="true"
                   @setLotInfoExt="setLotInfoExt"
                   @clearLotInfoExt="clearLotInfoExt"/>
     
      <DxDataGrid
        :ref="gridRefName"
        :show-borders="true"
        :show-column-lines="true"
        :columnAutoWidth="true"
        height="450"
        :selection="{ mode: 'single' }"
        @selectionChanged="selectedRow">
        <DxScrolling mode="virtual"/>
        <DxSorting mode="none"/>
        <DxColumn data-field="lotNumber" caption="Lot No."/>
        <DxColumn data-field="transTimeStr" caption="시간"/>
        <DxColumn data-field="inspType" caption="검사 구분">
          <DxLookup
            :data-source="inspType"
            value-expr="ID"
            display-expr="Name"/>
        </DxColumn>
        <DxColumn data-field="operationStr" caption="공정"/>
        <DxColumn data-field="codeName" caption="자재 코드"/>
        <DxColumn data-field="quantity" caption="수량"/>
        <DxColumn data-field="unit" caption="단위"/>
        <DxColumn data-field="userId" caption="검사자"/>
      </DxDataGrid>   
      <DxToolbar>
        <DxItem
          :options="btnOptDownloadCOAPDF"
          location="after"
          widget="dxButton"
        />
        <DxItem
          :options="btnOptDownloadCOAExcel"
          location="after"
          widget="dxButton"
        />
      </DxToolbar>
      <pdf id="coa" :src="pdfdata" :ref="pdfRefName"></pdf>
    </DxScrollView>
  </div>
</template>

<script>
  import {DxScrollView} from "devextreme-vue/scroll-view";
  import Transaction from "../_common/TRANSACTION";
  import {DxDataGrid,
          DxSorting,
          DxColumn,
          DxLookup,
          DxScrolling} from "devextreme-vue/data-grid";
  import DxToolbar, {DxItem} from "devextreme-vue/toolbar";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import {inspType} from "../../utils/syscodedata";
  import pdf from "vue-pdf"
  import axios from 'axios';
  import { DxLoadPanel } from 'devextreme-vue/load-panel';
  export default {
      components: {
        DxScrollView,
        Transaction,
        DxDataGrid,
        DxSorting,
        DxColumn,
        DxLookup,
        pdf,
        DxToolbar,
        DxItem,
        DxScrolling,  
        DxLoadPanel
      },
      data: function() {
        return {
          transactionRefName: 'transaction',
          gridRefName: 'grid',
          inspType: inspType,
          pdfRefName: 'pdfCOA',
          pdfdata: undefined,
          lotInfo: {},
          fileName: '',
          position: { of: '#Container' },
          loadingVisible: false,
          btnOptDownloadCOAPDF: {
            text: 'PDF 다운로드',
            icon: 'pdffile',
            onClick: this.downloadCOAPDF.bind(this)
          },
          btnOptDownloadCOAExcel: {
            text: 'EXCEL 다운로드',
            icon: 'xlsxfile',
            onClick: this.downloadCOAExcel.bind(this)
          }
        };
      },
      methods: {
        setLotInfoExt(lotInfo){
          dsu.getAxiosOBJGet('/api/mes/QCON/selectInspectionResultList', lotInfo).then(res =>{
                let data = res.data.data;
                if(res.data.result == 'SUCCESS'){
                    if(data.length == 0){
                      fn.notifyError('검사이력 없음');
                      this.$refs[this.transactionRefName].clear();
                      return;
                    }else{
                      this.setGridData(data);
                    }
                }else{
                  fn.notifyError(res.data.msg);
                }
              });
        },
        clearLotInfoExt(){
          this.clearPDFViewer();
          this.setGridData([]);
        },
        clearPDFViewer(){
          this.pdfdata = undefined;
          this.fileName = '';
        },
        setGridData(data){
          const grid = this.$refs[this.gridRefName].instance;
          grid.option('dataSource', data);
        },
        async selectedRow(event){
          this.clearPDFViewer();
          this.lotInfo = event.selectedRowsData[0];
          this.genCOA('VIEW', 'PDF');
        },
        async genCOA(action, fileType, event) {

          if(!this.lotInfo.lotNumber){
            return;
          }
          this.loadingVisible = true;
          var params = {}
          params.lotNumber = this.lotInfo.lotNumber;
          params.inspTransTime = this.lotInfo.transTime;
          params.operation = this.lotInfo.operation;
          params.insptype = this.lotInfo.inspType;
          params.regUserId = this.lotInfo.regUserId;
          params.genFileType = fileType;
          dsu.setDefaultParams(params);
          if (action == 'DOWNLOAD') {
            event.component.option('disabled', true);
          }

          try{
              const response = await axios({
                  url: '/api/mes/QCON/downloadCOA',
                  method: 'GET',
                  params: params,
                  headers: dsu.getHeaders(),
                  responseType: 'blob',
              });
              const blob = new Blob([response.data], { type: response.headers['content-type'] });
              const url = window.URL.createObjectURL(blob);
              if (params.insptype == 'IQC') {
                this.fileName = '수입검사 성적서(기준서)_' + response.headers.filename;
              } else {
                this.fileName = '공정검사 성적서(기준서)_' + response.headers.filename;
              }
              
              if (action == 'VIEW') {
                this.pdfdata =  url;
              } else if (action == 'DOWNLOAD') {
                event.component.option('disabled', false);
                const link = document.createElement('a');
                link.href = url
                link.download = this.fileName;
                link.click();
              }
          }catch(e){
            fn.notifyError("성적서 생성 예외상황 발생 : " + e);
          }
          this.loadingVisible = false;
        },
        downloadCOAPDF(event){
          this.genCOA('DOWNLOAD', 'PDF', event);
        },
        downloadCOAExcel(event){
          this.genCOA('DOWNLOAD', 'EXCEL', event);
        }
      }
    };
</script>

<style scoped>
  #Container{
    height: calc( 100vh - 50px );
    width: 100%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    background-color:white;
  }
  .coa{
    margin-top: 10px;
    width: 100%;
    max-width: 800px;
    height: 100vh;
  }
 
</style>