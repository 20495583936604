<template>
  <div>
    <DxLoadPanel
      :visible="loadingVisible"
      :show-indicator="true"
      :show-pane="false"
      :shading="true"
      :hide-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
    <div v-if="loadingVisible" class="custom-load-message">
      <div>태양광 모듈 주문정보 등록 중 입니다.</div>
      <div>데이터가 많을 경우 오래 걸릴 수 있습니다.</div>
    </div>
    <DxDataGrid
      id="gridContainer"
      :ref="gridRefName"
      :data-source="dataSource"
      :focused-row-enabled="true"
      :selection="{ mode: 'single' }"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      @cellDblClick="onDblClick"
      @toolbar-preparing="onToolbarPreparing">
    
      <DxSearchPanel :visible="true" width="300"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="infinite"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="false"/>

      <DxEditing
        :allow-adding="false"  
        :allow-updating="true"
        :allow-deleting="true"
        mode="popup"/>
      
      <DxColumn type="buttons">
        <DxButton name="edit"/>
      </DxColumn>

      <DxColumn
        data-field="orderPiNo"
        caption="Order PI No."
        :allowEditing="false"
        width="250">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^.{1,30}$"
          message="30자 까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="modelNo"
        caption="Model No."
        :header-filter="{ allowSearch: true }"
        width="250"
        :allowEditing="false">
        <DxPatternRule
          pattern="^.{1,50}$"
          message="50자 까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="orderName"
        caption="주문 명"
        :header-filter="{ allowSearch: true }">
        <DxPatternRule
          pattern="^.{1,100}$"
          message="100자 까지 등록 가능"/>
      </DxColumn>

      <DxMasterDetail
        :enabled="true"
        template="master-detail"/>
      <template #master-detail="{ data }">
        <History :master-detail-data="data"
                :url= "`/api/mes/MRSP/selectHistory`"
                :keyName="orderPiNo"
                :columns="historyColumns"/>
      </template>

      <template #file-cell-template="{ data }">
        {{data.data.fileName}}
      </template>
    </DxDataGrid>

    <DxPopup
      :visible="isPopupVisible"
      :show-title="true"
      title="주문정보 등록"
      :width="500"
      height="auto"
      @hiding="onPopupHiding">
      <div class="popup-content">
        <div class="form-group">
          <div class="form-label">주문정보 파일</div>
          <DxFileUploader
            ref="popupFileUploader"
            width="400"
            name="file"
            :uploadHeaders="fileUploadHeader"
            @uploaded="onPopupFileUploaded"
            upload-url="/api/mes/MRSP/uploadFile"
            select-button-text="파일 선택"
            :max-file-size="4800000"
            accept=".xlsx, .xls"
            />
        </div>

        <div class="form-group">
          <div class="form-label">Order PI No.</div>
          <DxTextBox v-model="newData.orderPiNo" :max-length="30" :disabled="true"/>
        </div>

        <div class="form-group">
          <div class="form-label">Model No.</div>
          <DxTextBox v-model="newData.modelNo" :max-length="50" :disabled="true"/>
        </div>

        <div class="form-group">
          <div class="form-label">주문 명</div>
          <DxTextBox v-model="newData.orderName" :max-length="100"/>
        </div>

        <div class="popup-buttons">
          <DxButton
            text="저장"
            type="normal"
            @click="saveNewData"
          />
          <DxButton
            text="취소"
            type="normal"
            @click="cancelPopup"
          />
        </div>
      </div>
    </DxPopup>

    <DxPopup
      :visible="isDetailPopupVisible"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      title="태양광 모듈 정보"
      width="95%"
      height="95%"
      @hiding="hideDetailPopup">
      <div class="popup-content-detail">
        <DxDataGrid
          class="detailGridContainer"
          :data-source="orderDetailData"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          @exporting="onExporting">
          
          <DxSearchPanel :visible="true" width="300"/>
          <DxHeaderFilter :visible="true"/>
          <DxScrolling mode="virtual"/>
          <DxSorting mode="multiple"/>
          <DxExport :enabled="true"/>
          <DxColumn caption="태양광 모듈 주문 정보">
            <DxColumn data-field="palletNo" caption="PALLET NO"/>
            <DxColumn data-field="serialNo" caption="SERIAL NO"/>
            <DxColumn data-field="ff" caption="FF"/>
            <DxColumn data-field="isc" caption="ISC"/>
            <DxColumn data-field="voc" caption="VOC"/>
            <DxColumn data-field="imax" caption="IMAX"/>
            <DxColumn data-field="vmax" caption="VMAX"/>
            <DxColumn data-field="pmax" caption="PMAX"/>
          </DxColumn>
          <DxColumn caption="입고 정보">
            <DxColumn data-field="importDate" caption="입고일"/>
          </DxColumn>
          <DxColumn caption="출고정보">
            <DxColumn data-field="shiptDate" caption="출고일"/>
          </DxColumn>
        </DxDataGrid>
      </div>
    </DxPopup>
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail
        } from "devextreme-vue/data-grid";
  import { DxFileUploader } from "devextreme-vue/file-uploader";     
  import { DxLoadPanel } from 'devextreme-vue/load-panel';
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import History from "../_common/SIMPLE_HISTORY";
  import DxButton from "devextreme-vue/button";
  import DxPopup from "devextreme-vue/popup";
  import DxTextBox from "devextreme-vue/text-box";
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxRequiredRule,
      DxPatternRule,
      DxMasterDetail,
      History,
      DxFileUploader,
      DxButton,
      DxPopup,
      DxTextBox,
      DxLoadPanel,
    },
    computed: {
      grid: function () { return this.$refs[this.gridRefName].instance; },
      fileUploader: function () { return this.$refs.popupFileUploader.instance; }
    },
    data() {
      return {
        gridRefName: 'grid',
        dataSource: dsu.getComDSGrid('/api/mes/MRSP', 'orderPiNo', '', 'Order PI No'),
        fileUploadHeader: { 'token': dsu.getHeaders().token, 'userId': dsu.getHeaders().userId, 'fileType': 'MRSP_FILE' },
        historyColumns: [{dataField: "orderName", caption: "주문 명" },
                         {dataField: "orderPiNo", caption: "Order PI No."},
                         {dataField: "modelNo", caption: "Model No."},
                         {dataField: "fileId", caption : "주문정보 파일", cellTemplate : "fileIdCellTemplate"},
                         {dataField: "userId", caption : "사용자"},
                         {dataField: "transTime", caption : "시간"}],
        isPopupVisible: false,
        newData: {
          orderPiNo: '',
          modelNo: '',
          orderName: '',
          fileId: '',
        },
        loadingVisible: false,
        orderDetailData: null,
        isDetailPopupVisible: false
      };
    },
    methods: {
      onExporting(e) {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const dateTimeStr = `${year}${month}${day}${hours}${minutes}${seconds}`;
        e.fileName = `${this.orderDetailData[0].orderPiNo}_${dateTimeStr}`;
      },
      onDblClick(e){
        if(e.rowType === 'data') {
          const orderPiNo = e.data.orderPiNo;
          this.loadOrderDetailData(orderPiNo);
          this.showDetailPopup();
        }
      },
      loadOrderDetailData(orderPiNo) {
        dsu.getAxiosOBJGet('/api/mes/MRSP/selectSolarPanelOrderData', { orderPiNo: orderPiNo })
          .then(res => {
            if(res.data.result === 'SUCCESS') {
              this.orderDetailData = res.data.data;
            } else {
              fn.notifyError('데이터 조회 실패');
            }
          })
          .catch(() => {
            fn.notifyError('데이터 조회 중 오류가 발생했습니다.');
          });
      },
      showDetailPopup() {
        this.isDetailPopupVisible = true;
      },
      hideDetailPopup() {
        this.isDetailPopupVisible = false;
      },
      showRegistrationPopup() {
        this.isPopupVisible = true;
        this.fileUploader.reset();
        this.newData = {
          orderPiNo: '',
          modelNo: '',
          orderName: '',
          fileId: ''
        };
      },
      onPopupHiding() {
        this.isPopupVisible = false;
      },
      onPopupFileUploaded(e) {
        const response = JSON.parse(e.request.responseText);
        if (response.result == 'SUCCESS') {
          const data = response.data;
          this.newData.fileId = data.fileId;
          this.newData.orderPiNo = data.orderPiNo;
          this.newData.modelNo = data.modelNo;
        } else {
          fn.notifyError('업로드 실패');
          e.component.option('uploadedMessage', "업로드 실패");
        }
      },
      saveNewData() {
        if(!this.newData.fileId) {
          fn.notifyError('주문정보 파일 업로 후 진행 부탁드립니다.');
          return;
        }

        if (!this.newData.orderName.trim()) {
          fn.notifyError('주문 명은 필수입니다.');
          return;
        }

        if (!this.newData.orderPiNo.trim()) {
          fn.notifyError('Order PI No.은 필수입니다.');
          return;
        }

        var params = {
          orderPiNo: this.newData.orderPiNo,
          orderName: this.newData.orderName,
          modelNo: this.newData.modelNo,
          fileId: this.newData.fileId
        }
        this.loadingVisible = true;
        dsu.getAxiosOBJPost('/api/mes/MRSP/insert', params).then(res => {
           this.loadingVisible = false;
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
              this.cancelPopup();
              this.grid.refresh();
            }else {
              fn.alert(response.msg);
            }
        }).catch(() => {
          this.loadingVisible = false;
          fn.alert('주문정보 등록 실패');
        });
      },
      cancelPopup() {
        this.isPopupVisible = false;
      },
      onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
            type: "custom",
            text: "등록",
            stylingMode: "outlined",
            icon: "add",
            width: 100,
            onClick: this.showRegistrationPopup
          }
        });
      }
    }
  };
</script>

<style lang="scss">
  #gridContainer {
    height: calc( 100vh - 50px );
    width: 100%;
  }
  
  .uploadedFileName {
    width: 100%;
    padding-left: 12px;
    padding-top: 10px;
    font-size: 16px;
    font-weight: bold;
    .button {
      margin-left: 10px;
    }
  }
  
  .popup-content {
    padding: 20px;
    .form-group {
      margin-bottom: 15px;

      .form-label {
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
    .popup-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      gap: 10px;
    }
  }

  .popup-content-detail {
    padding: 20px;
    .detailGridContainer {
      max-height: calc(100vh - 150px);
      width: 100%;
    }
  }

  .custom-load-message {
    position: fixed;
    top: calc(50% + 65px);
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 9999;
  }
</style>
