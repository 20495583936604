import { createStore } from 'devextreme-aspnet-data-nojquery';
import store from '../store';
import 'whatwg-fetch';

export const materialListStore = getStore('code', `/api/erp/EMMG/select`);
export const operationListStore = getStore('operation', `/api/erp/EMOG/select`);
export const materialCategoryStore = getStore('category', `/api/erp/EMMC/select`);

export const lotStatus = [
  { ID: '00', Name: 'Active' },
  { ID: '02', Name: 'Hold' },
  { ID: '04', Name: '현장 이동 중' },
  { ID: '99', Name: 'Terminate' }
];

export const lotType = [
  { ID: 'N', Name: '보통' },
  { ID: 'R', Name: '재입고' }
];

export const lotTypeWithAll = [
  { ID: '', Name: ' Lot Type : ALL' },
  { ID: 'N', Name: ' Lot Type : 보통' },
  { ID: 'R', Name: ' Lot Type : 재입고' }
];

export const materialGrade = [
  'A',
  'B',
  'C'
];

export const statusComplete = [
  { ID: 'Y', Name: '완료' },
  { ID: 'N', Name: '미 완료' }
];

export const operationLocation = [
  { ID: 'ILKANG', Name: '일강(음성 공장)' },
  { ID: 'OUTSOURSING', Name: '외주 업체' },
  { ID: 'CONSTRUCTION_SITE', Name: '건설 현장' }
];

export const materialUnit = [
  'KG',
  'EA',
  'BOX',
  'ROLL',
  '㎡',
  'PCS'
];

export const materialType = [
  '내화'
];

export const jobGrade = [
  '대표이사',
  '부대표이사',
  '수석',
  '책임',
  '선임',
  '소장',
  '사장',
  '전무',
  '실장',
  '상무',
  '이사',
  '부장',
  '차장',
  '과장',
  '대리',
  '계장',
  '주임',
  '사원',
];

export const dept = [
  '공장',
  '관리',
  '기술',
  '연구소',
  '영업',
  '자재',
  '품질',
  '현장',
  '홍보',
  '회계'
];

export const userAuth = [
  { ID: 'ADMIN', Name: '관리자' },
  { ID: 'USER', Name: '사용자' }
];

export const inspType = [
  { ID: 'IQC', Name: '수입검사' },
  { ID: 'SELF_INSP', Name: '공정검사' },
  { ID: 'OGI', Name: '출하검사' }
];

export const reasonCode = [
  { ID: 'INPUT_MISS', Name: '오 투입' },
  { ID: 'CHANGE_QTY', Name: '수량 보정' }
];

export const procurementMethodList = [
  { ID: 'METHOD1', Name: '조달 방법 1' },
  { ID: 'METHOD2', Name: '조달 방법 2' },
  { ID: 'METHOD3', Name: '조달 방법 3' }
];

export const eqpStatus = [
  { ID: '00', Name: 'Active' },
  { ID: '02', Name: 'BM' }
];

export const pmCycle = [
  { ID: 'MONTH', Name: '월' },
  { ID: 'QUARTER', Name: '분기' },
  { ID: 'SEMIANNUAL', Name: '반기' },
  { ID: 'YEAR', Name: '년' }
];

export const month = [
  { ID: '01', Name: '1 월' },
  { ID: '02', Name: '2 월' },
  { ID: '03', Name: '3 월' },
  { ID: '04', Name: '4 월' },
  { ID: '05', Name: '5 월' },
  { ID: '06', Name: '6 월' },
  { ID: '07', Name: '7 월' },
  { ID: '08', Name: '8 월' },
  { ID: '09', Name: '9 월' },
  { ID: '10', Name: '10 월' },
  { ID: '11', Name: '11 월' },
  { ID: '12', Name: '12 월' }
]

export const day = [
  { ID: '01', Name: '1 일' },
  { ID: '02', Name: '2 일' },
  { ID: '03', Name: '3 일' },
  { ID: '04', Name: '4 일' },
  { ID: '05', Name: '5 일' },
  { ID: '06', Name: '6 일' },
  { ID: '07', Name: '7 일' },
  { ID: '08', Name: '8 일' },
  { ID: '09', Name: '9 일' },
  { ID: '10', Name: '10 일' },
  { ID: '11', Name: '11 일' },
  { ID: '12', Name: '12 일' },
  { ID: '13', Name: '13 일' },
  { ID: '14', Name: '14 일' },
  { ID: '15', Name: '15 일' },
  { ID: '16', Name: '16 일' },
  { ID: '17', Name: '17 일' },
  { ID: '18', Name: '18 일' },
  { ID: '19', Name: '19 일' },
  { ID: '20', Name: '20 일' },
  { ID: '21', Name: '21 일' },
  { ID: '22', Name: '22 일' },
  { ID: '23', Name: '23 일' },
  { ID: '24', Name: '24 일' },
  { ID: '25', Name: '25 일' },
  { ID: '26', Name: '26 일' },
  { ID: '27', Name: '27 일' },
  { ID: '28', Name: '28 일' },
  { ID: '29', Name: '29 일' },
  { ID: '30', Name: '30 일' },
  { ID: '31', Name: '31 일' }
];


export const dayTolerance = [
  { ID: '01', Name: '± 1 일' },
  { ID: '02', Name: '± 2 일' },
  { ID: '03', Name: '± 3 일' },
  { ID: '04', Name: '± 4 일' },
  { ID: '05', Name: '± 5 일' },
  { ID: '06', Name: '± 6 일' },
  { ID: '07', Name: '± 7 일' },
  { ID: '08', Name: '± 8 일' },
  { ID: '09', Name: '± 9 일' },
  { ID: '10', Name: '± 10 일' },
  { ID: '11', Name: '± 11 일' },
  { ID: '12', Name: '± 12 일' },
  { ID: '13', Name: '± 13 일' },
  { ID: '14', Name: '± 14 일' },
  { ID: '15', Name: '± 15 일' },
  { ID: '16', Name: '± 16 일' },
  { ID: '17', Name: '± 17 일' },
  { ID: '18', Name: '± 18 일' },
  { ID: '19', Name: '± 19 일' },
  { ID: '20', Name: '± 20 일' },
  { ID: '21', Name: '± 21 일' },
  { ID: '22', Name: '± 22 일' },
  { ID: '23', Name: '± 23 일' },
  { ID: '24', Name: '± 24 일' },
  { ID: '25', Name: '± 25 일' },
  { ID: '26', Name: '± 26 일' },
  { ID: '27', Name: '± 27 일' },
  { ID: '28', Name: '± 28 일' },
  { ID: '29', Name: '± 29 일' },
  { ID: '30', Name: '± 30 일' },
  { ID: '31', Name: '± 31 일' }
];

export const eqpTestCheckStatus = [
  { ID: 'W', Name: '미 진행' },
  { ID: 'P', Name: 'Pass' },
  { ID: 'F', Name: 'Fail' }
];

export const branch = [
  '서울',
  '광주',
  '대전',
  '부산',
  '음성'
];

export const colors = [
  { ID: '105' },
  { ID: '133' },
  { ID: '304' },
  { ID: '417' },
  { ID: '514' },
  { ID: '529' },
  { ID: '587' },
  { ID: '705' },
  { ID: '813' },
  { ID: '815' },
  { ID: '904' },
  { ID: '920' },
  { ID: 'M6S880B' },
  { ID: 'M6S890K' },
  { ID: 'M9N930B' },
  { ID: 'M95780B' }
];

export const checkItemType = [
  { ID: 'TEXT', Name: '문자' },
  { ID: 'TEXT_AREA', Name: '서술' },
  { ID: 'NUMBER', Name: '숫자' },
  { ID: 'DATE', Name: '날짜' },
  { ID: 'OPTION', Name: '선택(단일)' },
  { ID: 'CHECK', Name: '선택(다중)' }
];

export const listYN = [
  'Y',
  'N'
];

export const productGrade = [
  '관급',
  '도급',
  '사급',
];

export const insulationCategory = [
  'G/W',
  'G/W, 세라믹울 25T',
  'G.C.C',
  'G.S.C ',
  '무단열',
  '우레탄',
  '우레탄 폼',
];

export const projectStep = [
  { ID: '1', Name: '영업 담당자 지정' },
  { ID: '2', Name: '고객 Contact' },
  { ID: '3', Name: '제품 등록' }
];

export const designStage = [
  { ID: '기본', Name: '기본' },
  { ID: '계획', Name: '계획' },
  { ID: '실시', Name: '실시' },
  { ID: '설계', Name: '설계' }
];

function getStore(key, url) {
  return createStore({
    key: key,
    loadUrl: url,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = { token: store.state.token, userId: store.state.userId };
    }
  });
}